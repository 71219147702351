<div class="modal" [@fadeInOut] *ngIf="isOpen" (click)="closeModal()">
  <div class="modal-content background-color-full rounded-8"
    (click)="$event.stopPropagation()">
    <div
      class="text-color fw-semibold text-semibig modal-flex-title mb-32 pb-12">
      {{'text_add_user' | translate }}
      <img [src]="themeService.close" class="cursor-pointer"
        (click)="closeModal()" />
    </div>

    <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()" class="modal-form">
      <div class="modal-form-block-half">
        <div class="text-color-light text-small uppercase">
          {{'text_first_name' | translate }}
        </div>
        <input type="text" class="form-control width-100"
          formControlName="first_name" />
      </div>
      <div class="modal-form-block-half">
        <div class="text-color-light text-small uppercase">
          {{'text_last_name' | translate }}
        </div>
        <input type="text" class="form-control width-100"
          formControlName="last_name" />
      </div>
      <div class="modal-form-block-half">
        <div class="text-color-light text-small uppercase">
          {{'text_birth_date' | translate }}
        </div>
        <input type="date" class="form-control width-100"
          formControlName="birth_date"
          required />
      </div>
      <mat-form-field class="flex-auto" appearance="fill">
        <mat-label>{{'langs' | translate }}</mat-label>
        <mat-select [formControl]="languagesControl" multiple>
          <mat-select-trigger>
            <div *ngIf="selectedLanguages.length > 0; else placeholderLangs">
              {{ getSelectedLanguageNames() }}
            </div>
            <ng-template #placeholderLangs>{{'choose_langs' | translate
              }}</ng-template>
          </mat-select-trigger>
          <mat-option *ngFor="let language of filteredLanguages | async"
            [value]="language"
            (onSelectionChange)="toggleSelectionLanguages(language)">
            {{language.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button [disabled]="addUserForm.invalid"
        class="btn-back-primary rounded-8 button-style cursor-pointer mt-32 width-100"
        type="submit">
        {{'text_confirm' | translate }}
      </button>
    </form>

  </div>
</div>
